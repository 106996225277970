import React from 'react';
import { Grid, Typography, Link, Box } from '@mui/material';
import india from "../../assets/img/india.png";

const Footer = () => {
    return (
        <Box component="footer" sx={{ padding: '20px', borderTop: '1px solid rgb(95, 99, 104)', width: '80%', margin: 'auto' }}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6">Google Play</Typography>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            marginTop: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Play Pass
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Play Points
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Gift cards
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Redeem
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Refund policy
                    </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h6">Kids & family</Typography>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            marginTop: '12px',
                            textDecoration: 'none',
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Parent Guide
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        display="block" 
                        gutterBottom 
                        sx={{ 
                            marginTop: '12px',
                            textDecoration: 'none',
                            color: 'rgb(95, 99, 104)',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Family sharing
                    </Link>
                </Grid>
            </Grid>

            <Grid container spacing={4} justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
                <Grid item xs={12} sm={10} md={10} style={{ display: "flex", gap: '10px' }}>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Terms of Service
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Privacy
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        About Google Play
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Developers
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        Google Store
                    </Link>
                    <Link 
                        href="#" 
                        variant="body2" 
                        gutterBottom 
                        sx={{ 
                            textDecoration: 'none', 
                            color: 'rgb(95, 99, 104)', 
                            fontSize: '12px',
                            '&:hover': {
                                color: '#01875f'
                            }
                        }}
                    >
                        All prices include GST.
                    </Link>
                </Grid>

                <Grid item xs={12} sm={2} md={2} sx={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <img src={india} alt="India Flag" style={{ marginRight: '8px', width: '20px', height: '16px' }} />
                    <Typography variant="body2" style={{ color: 'rgb(95, 99, 104)', fontSize: '14px' }}>
                        India (English)
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
