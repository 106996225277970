import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import logo from "../../assets/img/vecteezy_google-play-store-icon-logo-symbol_22484501.png";
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// Styled Tabs and Tab components
const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: '#01875f',
        height: '2px', 
    },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        color: '#01875f', 
    },
    '&.MuiTab-root': {
        fontSize: '13px', 
    },
}));

function Navbar() {
    const [value, setValue] = React.useState(1);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <AppBar position="static" style={{ backgroundColor: '#ffffff', color: '#000000', boxShadow: 'none' }}>
            <Toolbar style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px', display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="Google Play Logo" style={{ height: '60px', width: '60px' }} />
                
                <Typography 
                    variant="h6" 
                    style={{ 
                        marginLeft:isSmallScreen ? '0px':'10px', 
                        color: '#000000', 
                        fontSize: isSmallScreen ? '18px' : '24px', 
                        lineHeight: isSmallScreen ? '60px' : '60px', 
                        whiteSpace: 'nowrap' 
                    }}
                >
                    Google Play
                </Typography>
                
                <CustomTabs value={value} onChange={handleChange} style={{ marginLeft: '20px', flexGrow: 1 }}>
                    <CustomTab label="Games" />
                    <CustomTab label="Apps" />
                    <CustomTab label="Movies" />
                </CustomTabs>
                <IconButton edge="start" color="inherit" aria-label="search" style={{fontSize:"12px"}}>
                    <SearchIcon  />
                </IconButton>
                <IconButton color="inherit">
                    <HelpOutlineIcon />
                </IconButton>
                <IconButton color="inherit">
                    <AccountCircle />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
