import './App.css';
import Navbar from './components/Navbar/Navbar';
import AppContent from './components/AppContent/AppContent';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <Navbar />
      <AppContent />
      <Footer/>
    </>

  );
}

export default App;
