import React, { useState } from 'react';
import { Box, Typography, LinearProgress, Avatar, Button, IconButton, Link, Collapse, Grid, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DevicesIcon from '@mui/icons-material/Devices';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PolicyIcon from '@mui/icons-material/Policy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FlagIcon from '@mui/icons-material/Flag';

import img from '../../assets/img/Group 35124.png';

function AppContent() {
    const [isContactOpen, setIsContactOpen] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
    const handleContactToggle = () => {
        setIsContactOpen(!isContactOpen);
    };

    return (
        <Box sx={{ width:'90%',margin:'auto' }}>

            <Box sx={{ marginTop: '40px', padding: isSmallScreen ? '0 10px' : '15px' }}>
                <Typography sx={{ fontSize: isSmallScreen ? "22px" : "46px" }}>
                    Matka Baba Online Matka play
                </Typography>

                <Typography variant="h6" color="#01875f" sx={{ marginTop: '10px', fontSize: isSmallScreen ? '12px' : '15px' }}>
                    Matka Baba Online Matka
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: isSmallScreen ? 'flex-start' : 'center',
                        marginTop: '15px',
                        color: 'textSecondary',
                        fontSize: isSmallScreen ? '12px' : '14px'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '15px', marginBottom: isSmallScreen ? '10px' : '0' }}>
                        <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '16px' : '18px' }}>
                            4.9
                            <StarIcon sx={{ color: '#01875f', fontSize: '12px' }} />
                        </Typography>
                        <Typography variant="body2">
                            2K+ reviews
                        </Typography>
                    </Box>

                    {!isSmallScreen && <Typography variant="body2" sx={{ marginRight: '15px' }}>|</Typography>}

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '15px', marginBottom: isSmallScreen ? '10px' : '0' }}>
                        <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '16px' : '18px' }}>
                            50K+
                        </Typography>
                        <Typography variant="body2">
                            Downloads
                        </Typography>
                    </Box>

                    {!isSmallScreen && <Typography variant="body2" sx={{ marginRight: '15px' }}>|</Typography>}

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: '500' }}>
                        <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '16px' : '18px' }}>
                            3+
                        </Typography>
                        <Typography variant="body2">
                            Rated for 3+
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', marginTop: '20px' }}>
                    <a
                        href="https://server.ratankalyan.co/misc/apk"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            sx={{
                                marginRight: isSmallScreen ? '0' : '20px',
                                backgroundColor: '#01875f',
                                color: '#fff',
                                padding: isSmallScreen ? '8px 100px' : '10px 85px',
                                borderRadius: '10px',
                                textTransform: 'none',
                                fontSize: '16px',
                                marginBottom: isSmallScreen ? '10px' : '0',
                            }}
                        >
                            Install
                        </Button>
                    </a>
                    <Button
                        startIcon={<PlaylistAddIcon />}
                        sx={{
                            color: '#01875f',
                            textTransform: 'none',
                            fontSize: '16px',
                        }}
                    >
                        Add to wishlist
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px', color: 'textSecondary', fontSize: isSmallScreen ? '10px' : '14px' }}>
                    <DevicesIcon sx={{ fontSize: '18px' }} />
                    <Typography variant="body2" sx={{ marginLeft: '10px', fontSize: isSmallScreen ? '10px' : '12px', }}>
                        This app is available for all of your devices
                    </Typography>
                </Box>

                <Grid container spacing={isSmallScreen ? 2 : 4} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} md={6}>
                        <img
                            src={img}
                            alt="App screenshot"
                            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        cursor: 'pointer',
                                        marginBottom: '25px',
                                    }}
                                    onClick={handleContactToggle}
                                >
                                    <Typography variant="h5">
                                        Developer contact
                                    </Typography>
                                    <IconButton>
                                        <ExpandMoreIcon
                                            sx={{
                                                transform: isContactOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                                transition: 'transform 0.3s',
                                            }}
                                        />
                                    </IconButton>
                                </Box>

                                <Collapse in={isContactOpen}>
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <EmailIcon sx={{ marginRight: '10px', color: '#000' }} />
                                            <Link href="mailto:rajgurumatkaapp@gmail.com" sx={{ color: '#000' }}>
                                                monumeena9187@gmail.com
                                            </Link>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <WhatsAppIcon sx={{ marginRight: '10px', color: '#25d366' }} />
                                            <Link
                                                href="https://wa.me/917087045590"
                                                sx={{ color: '#000' }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                +91 70870 45590
                                            </Link>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <PolicyIcon sx={{ marginRight: '10px', color: '#000' }} />
                                            <Link href="https://rajgurustar.com/page/privacy-policy" sx={{ color: '#000' }}>
                                                Privacy policy
                                            </Link>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ padding: '20px' }}>
                <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                    How to Play
                </Typography>

                <Box
                    sx={{
                        width: isSmallScreen ? '100%' : '65%',
                        height: isSmallScreen ? '200px' : '250px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        position: 'relative',
                        backgroundColor: '#000',
                    }}
                >
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/your-video-id"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Box>
            </Box>

            {/* About this app section */}

            <Box sx={{ padding: '20px', marginLeft: '0px' }}>
                <Box>
                    <Typography variant="h5" sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        About this app
                        <ArrowForwardIcon sx={{ marginLeft: '8px', color: 'rgb(95, 99, 104)' }} />
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '10px', color: 'rgb(95, 99, 104)' }}>
                        Please note that our app is only for entertainment purposes. No real money involved in our app.
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '10px', color: 'rgb(95, 99, 104)' }}>
                        Rajguru star online matka - Online Matka Play App is Online Rajguru start online matka Play App for Entertainment Purpose Only. <br /> <br />Online Matka Play App for Entertainment Purpose Only...
                    </Typography>

                    <Typography variant="h6" sx={{ margin: '20px 0px', color: 'textSecondary', fontWeight: '400' }}>
                        Updated on
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '20px', color: 'textSecondary', }}>
                        May 24, 2022
                    </Typography>

                    <Box >
                        <Button variant="p" sx={{ padding: '5px 10px', borderRadius: '20px', border: '1px solid rgb(95, 99, 104)', fontWeight: '300' }}>
                            Entertainment
                        </Button>
                    </Box>
                </Box>

                {/* Data safety section */}
                <Box sx={{ marginTop: '30px' }}>
                    <Typography variant="h5" sx={{ marginBottom: '20px' }}>
                        Data safety
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px', color: 'rgb(95, 99, 104)' }}>
                        Developers can show information here about how their app collects and uses your data.
                        <Link href="#" sx={{ color: '#000' }}>
                            Learn more about data safety
                        </Link>
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '15px',
                        borderRadius: '8px',
                        border: '1px solid #d1d1d1',
                        backgroundColor: '#f9f9f9',
                    }}>
                        <InfoIcon sx={{ marginRight: '10px', color: 'rgb(95, 99, 104)' }} />
                        <Typography variant="body2">
                            No information available
                        </Typography>
                    </Box>
                </Box>
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '20px', }}>

                {/* Ratings and Reviews Title */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{}}>
                        Ratings and reviews
                    </Typography>
                    <ArrowForwardIcon sx={{ marginLeft: '8px', fontSize: 20 }} />
                </Box>

                <Box sx={{ width: '30%' }} />

                {/* Verified Text with Info Icon */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Ratings and reviews are verified
                    </Typography>
                    <IconButton size="small" sx={{ marginLeft: '4px' }}>
                        <InfoOutlinedIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                </Box>

            </Box>
            <Box sx={{ padding: isSmallScreen ? 0 : 3, width: isSmallScreen ? "100%" : '60%' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: isSmallScreen ? 2 : 3 }}>
                        <Typography variant="h3" >4.9</Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            {[1, 2, 3, 4].map((_, index) => (
                                <StarIcon key={index} sx={{ color: '#01875f', fontSize: 30 }} />
                            ))}
                        </Box>

                        {/* Reviews Text */}
                        <Typography variant="body2" sx={{ marginTop: 1, color: 'text.secondary' }}>
                            2k+ reviews
                        </Typography>
                    </Box>

                    {/* Rating Distribution */}
                    <Box sx={{ marginTop: 1, flex: 1 }}>
                        {[5, 4, 3, 2, 1].map((value) => (
                            <Box key={value} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Typography variant="body2">{value}</Typography>
                                <StarIcon sx={{ color: '#01875f', fontSize: 20, marginLeft: 0.5 }} />
                                <LinearProgress variant="determinate" value={value === 5 ? 90 : value === 4 ? 7 : 1} sx={{ flexGrow: 1, marginLeft: 2, height: 8, borderRadius: 5 }} />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* Review Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                    <Avatar src="/path-to-avatar.jpg" />
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="subtitle1">Sunil Kumar</Typography>

                        {/* Stars and Date */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {[1, 2, 3, 4].map((_, index) => (
                                <StarIcon key={index} sx={{ color: '#01875f', fontSize: 12, marginRight: 0.5 }} />
                            ))}
                            <Typography variant="caption" color="text.secondary">Nov 05, 2022</Typography>
                        </Box>
                    </Box>
                    <IconButton sx={{ marginLeft: 'auto' }}>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" sx={{ marginTop: 2, fontSize: '14px' }}>
                    This was really an exceptional application I have been using for many days without any problem and getting a great experience for getting online matka results.
                </Typography>

                {/* Review Actions */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, fontSize: '14px' }}>


                    <Typography variant="body2" color="text.secondary">Did you find this helpful?</Typography>
                    <Box sx={{ marginLeft: '42px', display: 'flex' }}>
                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>Yes</Button>

                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>No</Button>
                    </Box>
                </Box>

                {/* Review Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                    <Avatar src="/path-to-avatar.jpg" />
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="subtitle1">Ravi Bhambu</Typography>

                        {/* Stars and Date */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {[1, 2, 3, 4].map((_, index) => (
                                <StarIcon key={index} sx={{ color: '#01875f', fontSize: 12, marginRight: 0.5 }} />
                            ))}
                            <Typography variant="caption" color="text.secondary">Nov 05, 2022</Typography>
                        </Box>
                    </Box>
                    <IconButton sx={{ marginLeft: 'auto' }}>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" sx={{ marginTop: 2, fontSize: '14px' }}>
                    This app is amazing! I have downloaded about seven apps that claim to help me make money, but this one has been the most legit. It's funny and easy to play. There are several different games you can play, such as a virtual casino game, roulette and blackjack. I've made almost $30 in just a few minutes.                </Typography>

                {/* Review Actions */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, fontSize: '14px' }}>


                    <Typography variant="body2" color="text.secondary">Did you find this helpful?</Typography>
                    <Box sx={{ marginLeft: '42px', display: 'flex' }}>
                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>Yes</Button>

                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>No</Button>
                    </Box>
                </Box>
                {/* Review Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                    <Avatar src="/path-to-avatar.jpg" />
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="subtitle1">Manish Muhal</Typography>

                        {/* Stars and Date */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {[1, 2, 3, 4].map((_, index) => (
                                <StarIcon key={index} sx={{ color: '#01875f', fontSize: 12, marginRight: 0.5 }} />
                            ))}
                            <Typography variant="caption" color="text.secondary">Nov 05, 2022</Typography>
                        </Box>
                    </Box>
                    <IconButton sx={{ marginLeft: 'auto' }}>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" sx={{ marginTop: 2, fontSize: '14px' }}>
                    Give fast withdraw and deposit Nice application for getting online matka results with very helpful features and I am glad to use this awesome application which works without any difficulty                </Typography>

                {/* Review Actions */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, fontSize: '14px' }}>


                    <Typography variant="body2" color="text.secondary">Did you find this helpful?</Typography>
                    <Box sx={{ marginLeft: '42px', display: 'flex' }}>
                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>Yes</Button>

                        <Button variant="" sx={{
                            marginRight: 1,
                            borderRadius: "12px",
                            border: "1px solid rgb(95, 99, 104)",
                            padding: '1px ',
                            fontSize: '12px',
                            color: "rgb(95, 99, 104)"
                        }}>No</Button>
                    </Box>

                </Box>
                <Button style={{ color: '#01875f', textTransform: 'none', marginTop: '12px' }}>
                    See all reviews
                </Button>

                <Typography variant="h5" style={{ margin: '25px 0px' }}>
                    What's new
                </Typography>

                <Typography variant="body2" color="text.secondary" style={{ marginBottom: '12px' }}>Rajguru star Online Matka play
                </Typography>
                <Button
                    startIcon={<FlagIcon />}
                    style={{
                        color: 'rgb(95, 99, 104)',
                        textTransform: 'none',
                        fontSize: '14px',
                        padding: 0,
                        minWidth: 'auto'
                    }}
                >
                </Button>

                <a href='' style={{ marginLeft: '12px', textDecoration: "none", color: '#000' }}> Flag as inappropriate</a>
            </Box>
            {/* <hr style={{width:'90%',marginTop:'140px'}}></hr> */}

        </Box>
    );
}

export default AppContent;
